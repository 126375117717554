export const FETCH_USER_SUBSCRIPTIONS_REQUEST = 'FETCH_USER_SUBSCRIPTIONS_REQUEST';
export const FETCH_USER_SUBSCRIPTIONS_SUCCESS = 'FETCH_USER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_USER_SUBSCRIPTIONS_FAILURE = 'FETCH_USER_SUBSCRIPTIONS_FAILURE';

export const CREATE_USER_SUBSCRIPTION_REQUEST = 'CREATE_USER_SUBSCRIPTION_REQUEST';
export const CREATE_USER_SUBSCRIPTION_SUCCESS = 'CREATE_USER_SUBSCRIPTION_SUCCESS';
export const CREATE_USER_SUBSCRIPTION_FAILURE = 'CREATE_USER_SUBSCRIPTION_FAILURE';

export const DESTROY_USER_SUBSCRIPTION_REQUEST = 'DESTROY_USER_SUBSCRIPTION_REQUEST';
export const DESTROY_USER_SUBSCRIPTION_SUCCESS = 'DESTROY_USER_SUBSCRIPTION_SUCCESS';
export const DESTROY_USER_SUBSCRIPTION_FAILURE = 'DESTROY_USER_SUBSCRIPTION_FAILURE';
